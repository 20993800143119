import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/layout";

const Hero = styled.div``;

const OfferTemplate = (props) => {
  const originLink = get(props, "location.orgin");
  const { title, slug } = get(props, "pageContext");
  const { offerImage } = get(props, "data.contentfulOffers", {});

  return (
    <Layout location={props.location}>
      <div style={{ background: "#fff" }}>
        <Helmet>
          <title>{`${title} | Diamantidou School of Music, Dance & Drama`}</title>
          {originLink && (
            <link rel="canonical" href={`${originLink}/offers/`} />
          )}
          <meta
            name="description"
            content={
              "Diamantidou School of Music, Dance & Drama offers a wide choice of various directions in arts and talent development. Our classes range from music, dance and theatrical workshops as well as gym, modelling and taekwondo. For our younger students we offer afternoon creative classes."
            }
          />
        </Helmet>
        <Hero>{offerImage && <Img {...offerImage} />}</Hero>
        <div className="wrapper">
          {/* <h1 className="section-headline">{offer.title}</h1>
          <p
            style={{
              display: "block",
            }}
          >
            {offer.publishDate}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: offer.body.childMarkdownRemark.html,
            }}
          /> */}
        </div>
      </div>
    </Layout>
  );
};

export default OfferTemplate;

export const pageQuery = graphql`
  query OfferBySlug($slug: String!) {
    contentfulOffers(slug: { eq: $slug }) {
      title
      offerImage: offerImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
  }
`;
